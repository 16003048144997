import React from 'react';
import logo from './images/lemoncatlogo.svg';
import './App.scss';
import AboutTitle from './images/AboutSectionTitle3.svg';
import PortfolioTitle from './images/PortfolioSectionTitle2.svg';
import ContactTitle from './images/ContactSectionTitle2.svg';
import PortfolioCarousel from './containers/Carousel/carousel';
import {LinkedIn, GitHub, Menu} from '@mui/icons-material';
import {Button, Drawer, Box, List, ListItem, ListItemText} from '@mui/material';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Resume from './assets/ChristinaSaylorResume.pdf';

function App() {
  AOS.init();

  let isMobile = false;
  if (window.innerWidth < 769) {
    isMobile = true;
  }

  const [state, setState] = React.useState({
    navOpen: false
  });

  const toggleDrawer = () => (event: any) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({...state, navOpen: !state.navOpen})
  }

  return (
    <div className="App">
    <header className="App-header">
      {isMobile ? 
        <div className="mobile-navbar">
          <Button onClick={toggleDrawer()}><Menu/></Button>
          <Drawer anchor='top' open={state.navOpen} onClose={toggleDrawer()}>
            <Box
              role="presentation"
              onClick={toggleDrawer()}
              onKeyDown={toggleDrawer()}
            >
              <List>
                <ListItem button key="about">
                  <a href="#about"><ListItemText>About</ListItemText></a>
                </ListItem>
                <ListItem button key="work">
                  <a href="#work"><ListItemText>My Work</ListItemText></a>
                </ListItem>
                <ListItem button key="contact">
                  <a href="#contact"><ListItemText>Contact Me</ListItemText></a>
                </ListItem>
              </List>
            </Box>
          </Drawer>
        </div>
        :
        <div className="navbar">
          <ul className="nav">
            <li><a href='#about'>About</a></li>
            <li><a href='#work'>My Work</a></li>
            <li><a href='#contact'>Contact Me</a></li>
          </ul>
        </div>
      }
    </header>
    <body className="App-body">
      <div className='splash-container'>
          <div className='logo-container' data-aos={!isMobile ? 'fade-up' : ''}>
            <img src={logo} className='logo' alt='lemoncat logo'/>
            <h1>Web Design & Development</h1>
          </div>
      </div>
      <div id="about" className="section-container about-container">
        <div className="section-title-container" data-aos={!isMobile ? 'fade-right' : 'fade-up'}>
          <img src={AboutTitle} alt="About" className="section-title" />
        </div>
        <div className="section-content">
            <div className="about-image"/>
            <p>My name is Christina Saylor and I'm a developer based in Greenville, SC. I'm particularly passionate about front-end development because I love bringing designs to life and creating a satisfying experience for users. My must-haves in life are cats, coffee, and clean code.</p>
            <div className='about-btns'>
              <button><a href={Resume} target="_blank" download>Download my resume</a></button>
              <button><a href="#contact">Contact me</a></button>
            </div>
        </div>
      </div>
      <div id="work" className="section-container portfolio-container">
        <div className="section-content">
          <div className="project-container">
            <PortfolioCarousel isMobile={isMobile}/>
          </div>
        </div>
        <div className="section-title-wrapper" data-aos={!isMobile ? 'fade-left' : 'fade-up'}>
          <img src={PortfolioTitle} alt="Portfolio" className="section-title" />
        </div>
      </div>
      <div id="contact" className="section-container contact-container">
        <div className="section-title-wrapper" data-aos={!isMobile ? 'fade-right' : 'fade-up'}>
          <img src={ContactTitle} alt="Contact" className="section-title" />
        </div>
        <div className="section-content">
          <p>For more information, feel free to reach out to me at <a href="mailto: christina.renee106@gmail.com">christina.renee106@gmail.com</a>.</p>
          <div className="contact-buttons">
            <a href="https://www.linkedin.com/in/christina-s-943b9b1a2/" target="_blank"><LinkedIn fontSize="large"/></a>
            <a href="https://github.com/c-saylor" target="_blank"><GitHub fontSize="large"/></a>
          </div>
        </div>
      </div>
    </body>
  </div>
  );
}

export default App;
