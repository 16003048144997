import DarkLightScreenshot from '../../images/dark-light-mode.PNG';
import JokeTellerScreenshot from '../../images/joke-teller.PNG';
import MonstersRolodexScreenshot from '../../images/monsters-rolodex.PNG';
import MusicPlayerScreenshot from '../../images/music-player.PNG';
import PictureInPictureScreenshot from '../../images/picture-in-picture.PNG';
import QuoteGeneratorScreenshot from '../../images/quote-generator.PNG';
import EcommerceScreenshot from '../../images/e-commerce.png';

export const portfolioItems = [
    {
        title: 'E-Commerce',
        screenshot: `${EcommerceScreenshot}`,
        liveLink: 'https://c-saylor-ecommerce-3eb9b7b71613.herokuapp.com/',
        githubLink: 'https://github.com/c-saylor/ecommerce',
        description: 'A functional but fake e-commerce site. Users can browse items, add them to their cart, and check out using Stripe integration.'
    },
    {
        title: 'Dark/Light Mode',
        screenshot: `${DarkLightScreenshot}`,
        liveLink: 'https://dark-light-mode-318b8841d5fb.herokuapp.com/',
        githubLink: 'https://github.com/c-saylor/dark-light-mode',
        description: 'This project is an example of DOM manipulation. The user can toggle between a light and dark theme and are shown examples of different elements which change appearance according to the selected theme. The selected theme is retained using the local storage of the browser.'
    },
    {
        title: 'Joke Teller',
        screenshot: `${JokeTellerScreenshot}`,
        liveLink: 'https://joke-teller-1fd9ab40b4b8.herokuapp.com/',
        githubLink: 'https://github.com/c-saylor/joke-teller',
        description: 'The joke teller fetches a joke from an API and uses text-to-speech to read the joke aloud to the user at the press of a button.'
    },
/*     {
        title: 'Monsters Rolodex',
        screenshot: `${MonstersRolodexScreenshot}`,
        liveLink: 'https://monsters-rolodex-live-example.herokuapp.com/',
        githubLink: 'https://github.com/c-saylor/monsters-rolodex',
        description: 'The user is shown a list of monsters. By entering text into the searchbox, they can filter this list to only the monsters that match their search criteria.'
    }, */
    {
        title: 'Music Player',
        screenshot: `${MusicPlayerScreenshot}`,
        liveLink: 'https://music-player-dfe5fa243ec8.herokuapp.com/',
        githubLink: 'https://github.com/c-saylor/music-player',
        description: 'This project displays a simple music player interface to the user which uses the HTML 5 audio element. The user can play, pause, and cycle through songs.'
    },
    {
        title: 'Picture-in-Picture',
        screenshot: `${PictureInPictureScreenshot}`,
        liveLink: 'https://picture-in-picture-3805952a56c4.herokuapp.com/',
        githubLink: 'https://github.com/c-saylor/picture-in-picture',
        description: 'A picture-in-picture and screen capture API allow the user to display their screen inside the browser. The box displaying the screen can be dragged and dropped to any part of the window.'
    },
/*     {
        title: 'Quote Generator',
        screenshot: `${QuoteGeneratorScreenshot}`,
        liveLink: 'https://quote-generator-live-example.herokuapp.com/',
        githubLink: 'https://github.com/c-saylor/quote-generator',
        description: 'The quote generator fetches a random quote from the API and displays it on the screen. By simply pressing the New Quote button on the right, users will be given a new random quote. If the user likes the quote, they may choose to tweet it using the button on the left which will open Twitter in a new tab and autofill the quote and author into the tweet modal.'
    } */
];