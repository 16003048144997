import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { portfolioItems } from "../Portfolio/portfolio-items";

function PortfolioCarousel(isMobile) {
    return (
      <Carousel showStatus={false} infiniteLoop>
        {
          portfolioItems.map(item => 
            <div>
              <h3 className='project-title'>{item.title}</h3>
              <div className='project-image-container'>
                <img src={item.screenshot} className='project-image'/>
                <div className='project-image-overlay'>
                  <div className='link-container'>
                    <a href={item.liveLink} target='_blank'>VIEW LIVE EXAMPLE</a>
                    <a href={item.githubLink} target='_blank'>VIEW ON GITHUB</a>
                  </div>
                </div>
              </div>
                <div className="mobile-buttons">
                  <button><a href={item.liveLink} target="_blank">Live Example</a></button>
                  <button><a href={item.githubLink} target="_blank">View on GitHub</a></button>
                </div>
              <p className='project-description'>{item.description}</p>
            </div>
          )
        }
      </Carousel>
    );
}

export default PortfolioCarousel;